<template>
  <!-- <div class=""> -->
    <div class="h-screen error-page relative m-0 mt-0">
      <div class="error-page-content w-full">
        <h1>{{ error.statusCode === 404 ? '404' : 'Oops, something went wrong!' }}</h1>
        <p>{{ error.statusMessage }}</p>
        <router-link
           to="/"
           class="inline-block sm:py-3 py-2 sm:px-6 px-4 border border-transparent text-base font-bold rounded-md text-white bg-[#f69532]"
         >
           Back To Home
         </router-link>
      </div>
    </div>
  <!-- </div> -->
</template>

<script setup lang="ts">
const error: any = useError();
</script>


<style scoped>

.error-page {
  background-image: url(./public/images/error-404.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  text-align: center;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
.error-page::after{
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  background-color: #114c8e;
  width: 100%;
  height: 100%;
  opacity: 0.7;
}
.error-page-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -90%);
    z-index: 55;
}
.error-page-content h1 {
    font-size: 220px;
    line-height: 1.1;
    font-weight: 900;
    color: #fff;
    opacity: 0.9;
    font-family: serif;  
}
.error-page-content p {
    font-size: 26px;
    line-height: 1.2;
    color: #fff;
    font-weight: 700;
    margin-bottom: 20px;
}
@media (max-width: 575px) {
  .error-page-content h1{
    font-size: 180px;
  }
  .error-page-content p{
    font-size: 22px
  }
}
@media (max-width: 440px) {
  .error-page-content h1{
    font-size: 120px;
  }
}
</style>