import { default as aboutWGE3IiZiDrMeta } from "/var/www/html/water-taxi/water-taxi-front/src/pages/about.vue?macro=true";
import { default as book_45charterrVr4QuryjoMeta } from "/var/www/html/water-taxi/water-taxi-front/src/pages/book-charter.vue?macro=true";
import { default as book_45parcels61FUowt3QzMeta } from "/var/www/html/water-taxi/water-taxi-front/src/pages/book-parcels.vue?macro=true";
import { default as book_45ticketsDb50g2x560Meta } from "/var/www/html/water-taxi/water-taxi-front/src/pages/book-tickets.vue?macro=true";
import { default as booking_45details5yn9LmHT80Meta } from "/var/www/html/water-taxi/water-taxi-front/src/pages/booking-details.vue?macro=true";
import { default as buy_45pass8wNkXJO4etMeta } from "/var/www/html/water-taxi/water-taxi-front/src/pages/buy-pass.vue?macro=true";
import { default as charter_45booking_45info0J3Ciw9sx8Meta } from "/var/www/html/water-taxi/water-taxi-front/src/pages/charter-booking-info.vue?macro=true";
import { default as charter_45detailLPpwlwzVQvMeta } from "/var/www/html/water-taxi/water-taxi-front/src/pages/charter-detail.vue?macro=true";
import { default as _91id_93rircgZzNW2Meta } from "/var/www/html/water-taxi/water-taxi-front/src/pages/charter-ticket/[id].vue?macro=true";
import { default as contact_45us2OhMkTwCZfMeta } from "/var/www/html/water-taxi/water-taxi-front/src/pages/contact-us.vue?macro=true";
import { default as index1lPezPQnktMeta } from "/var/www/html/water-taxi/water-taxi-front/src/pages/index.vue?macro=true";
import { default as _91title_93vxJ1Q7P2EHMeta } from "/var/www/html/water-taxi/water-taxi-front/src/pages/news/[category]/[title].vue?macro=true";
import { default as indexoj6UOJS2bgMeta } from "/var/www/html/water-taxi/water-taxi-front/src/pages/news/index.vue?macro=true";
import { default as parcel_45book_45infohY6lwLdU5QMeta } from "/var/www/html/water-taxi/water-taxi-front/src/pages/parcel-book-info.vue?macro=true";
import { default as _91id_935oSvk38RLSMeta } from "/var/www/html/water-taxi/water-taxi-front/src/pages/parcel-ticket/[id].vue?macro=true";
import { default as _91id_937EXHkvdtgJMeta } from "/var/www/html/water-taxi/water-taxi-front/src/pages/pass/[id].vue?macro=true";
import { default as passenger_45infoIw08uNrmWTMeta } from "/var/www/html/water-taxi/water-taxi-front/src/pages/passenger-info.vue?macro=true";
import { default as policyE1tRE15V5ZMeta } from "/var/www/html/water-taxi/water-taxi-front/src/pages/policy.vue?macro=true";
import { default as profile1WlMjTUgsAMeta } from "/var/www/html/water-taxi/water-taxi-front/src/pages/profile.vue?macro=true";
import { default as terms_45conditionsKgxm1FURxYMeta } from "/var/www/html/water-taxi/water-taxi-front/src/pages/terms-conditions.vue?macro=true";
import { default as _91id_93mMvUAXmRpuMeta } from "/var/www/html/water-taxi/water-taxi-front/src/pages/ticket/[id].vue?macro=true";
export default [
  {
    name: aboutWGE3IiZiDrMeta?.name ?? "about",
    path: aboutWGE3IiZiDrMeta?.path ?? "/about",
    meta: aboutWGE3IiZiDrMeta || {},
    alias: aboutWGE3IiZiDrMeta?.alias || [],
    redirect: aboutWGE3IiZiDrMeta?.redirect,
    component: () => import("/var/www/html/water-taxi/water-taxi-front/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: book_45charterrVr4QuryjoMeta?.name ?? "book-charter",
    path: book_45charterrVr4QuryjoMeta?.path ?? "/book-charter",
    meta: book_45charterrVr4QuryjoMeta || {},
    alias: book_45charterrVr4QuryjoMeta?.alias || [],
    redirect: book_45charterrVr4QuryjoMeta?.redirect,
    component: () => import("/var/www/html/water-taxi/water-taxi-front/src/pages/book-charter.vue").then(m => m.default || m)
  },
  {
    name: book_45parcels61FUowt3QzMeta?.name ?? "book-parcels",
    path: book_45parcels61FUowt3QzMeta?.path ?? "/book-parcels",
    meta: book_45parcels61FUowt3QzMeta || {},
    alias: book_45parcels61FUowt3QzMeta?.alias || [],
    redirect: book_45parcels61FUowt3QzMeta?.redirect,
    component: () => import("/var/www/html/water-taxi/water-taxi-front/src/pages/book-parcels.vue").then(m => m.default || m)
  },
  {
    name: book_45ticketsDb50g2x560Meta?.name ?? "book-tickets",
    path: book_45ticketsDb50g2x560Meta?.path ?? "/book-tickets",
    meta: book_45ticketsDb50g2x560Meta || {},
    alias: book_45ticketsDb50g2x560Meta?.alias || [],
    redirect: book_45ticketsDb50g2x560Meta?.redirect,
    component: () => import("/var/www/html/water-taxi/water-taxi-front/src/pages/book-tickets.vue").then(m => m.default || m)
  },
  {
    name: booking_45details5yn9LmHT80Meta?.name ?? "booking-details",
    path: booking_45details5yn9LmHT80Meta?.path ?? "/booking-details",
    meta: booking_45details5yn9LmHT80Meta || {},
    alias: booking_45details5yn9LmHT80Meta?.alias || [],
    redirect: booking_45details5yn9LmHT80Meta?.redirect,
    component: () => import("/var/www/html/water-taxi/water-taxi-front/src/pages/booking-details.vue").then(m => m.default || m)
  },
  {
    name: buy_45pass8wNkXJO4etMeta?.name ?? "buy-pass",
    path: buy_45pass8wNkXJO4etMeta?.path ?? "/buy-pass",
    meta: buy_45pass8wNkXJO4etMeta || {},
    alias: buy_45pass8wNkXJO4etMeta?.alias || [],
    redirect: buy_45pass8wNkXJO4etMeta?.redirect,
    component: () => import("/var/www/html/water-taxi/water-taxi-front/src/pages/buy-pass.vue").then(m => m.default || m)
  },
  {
    name: charter_45booking_45info0J3Ciw9sx8Meta?.name ?? "charter-booking-info",
    path: charter_45booking_45info0J3Ciw9sx8Meta?.path ?? "/charter-booking-info",
    meta: charter_45booking_45info0J3Ciw9sx8Meta || {},
    alias: charter_45booking_45info0J3Ciw9sx8Meta?.alias || [],
    redirect: charter_45booking_45info0J3Ciw9sx8Meta?.redirect,
    component: () => import("/var/www/html/water-taxi/water-taxi-front/src/pages/charter-booking-info.vue").then(m => m.default || m)
  },
  {
    name: charter_45detailLPpwlwzVQvMeta?.name ?? "charter-detail",
    path: charter_45detailLPpwlwzVQvMeta?.path ?? "/charter-detail",
    meta: charter_45detailLPpwlwzVQvMeta || {},
    alias: charter_45detailLPpwlwzVQvMeta?.alias || [],
    redirect: charter_45detailLPpwlwzVQvMeta?.redirect,
    component: () => import("/var/www/html/water-taxi/water-taxi-front/src/pages/charter-detail.vue").then(m => m.default || m)
  },
  {
    name: _91id_93rircgZzNW2Meta?.name ?? "charter-ticket-id",
    path: _91id_93rircgZzNW2Meta?.path ?? "/charter-ticket/:id()",
    meta: _91id_93rircgZzNW2Meta || {},
    alias: _91id_93rircgZzNW2Meta?.alias || [],
    redirect: _91id_93rircgZzNW2Meta?.redirect,
    component: () => import("/var/www/html/water-taxi/water-taxi-front/src/pages/charter-ticket/[id].vue").then(m => m.default || m)
  },
  {
    name: contact_45us2OhMkTwCZfMeta?.name ?? "contact-us",
    path: contact_45us2OhMkTwCZfMeta?.path ?? "/contact-us",
    meta: contact_45us2OhMkTwCZfMeta || {},
    alias: contact_45us2OhMkTwCZfMeta?.alias || [],
    redirect: contact_45us2OhMkTwCZfMeta?.redirect,
    component: () => import("/var/www/html/water-taxi/water-taxi-front/src/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: index1lPezPQnktMeta?.name ?? "index",
    path: index1lPezPQnktMeta?.path ?? "/",
    meta: index1lPezPQnktMeta || {},
    alias: index1lPezPQnktMeta?.alias || [],
    redirect: index1lPezPQnktMeta?.redirect,
    component: () => import("/var/www/html/water-taxi/water-taxi-front/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91title_93vxJ1Q7P2EHMeta?.name ?? "news-category-title",
    path: _91title_93vxJ1Q7P2EHMeta?.path ?? "/news/:category()/:title()",
    meta: _91title_93vxJ1Q7P2EHMeta || {},
    alias: _91title_93vxJ1Q7P2EHMeta?.alias || [],
    redirect: _91title_93vxJ1Q7P2EHMeta?.redirect,
    component: () => import("/var/www/html/water-taxi/water-taxi-front/src/pages/news/[category]/[title].vue").then(m => m.default || m)
  },
  {
    name: indexoj6UOJS2bgMeta?.name ?? "news",
    path: indexoj6UOJS2bgMeta?.path ?? "/news",
    meta: indexoj6UOJS2bgMeta || {},
    alias: indexoj6UOJS2bgMeta?.alias || [],
    redirect: indexoj6UOJS2bgMeta?.redirect,
    component: () => import("/var/www/html/water-taxi/water-taxi-front/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: parcel_45book_45infohY6lwLdU5QMeta?.name ?? "parcel-book-info",
    path: parcel_45book_45infohY6lwLdU5QMeta?.path ?? "/parcel-book-info",
    meta: parcel_45book_45infohY6lwLdU5QMeta || {},
    alias: parcel_45book_45infohY6lwLdU5QMeta?.alias || [],
    redirect: parcel_45book_45infohY6lwLdU5QMeta?.redirect,
    component: () => import("/var/www/html/water-taxi/water-taxi-front/src/pages/parcel-book-info.vue").then(m => m.default || m)
  },
  {
    name: _91id_935oSvk38RLSMeta?.name ?? "parcel-ticket-id",
    path: _91id_935oSvk38RLSMeta?.path ?? "/parcel-ticket/:id()",
    meta: _91id_935oSvk38RLSMeta || {},
    alias: _91id_935oSvk38RLSMeta?.alias || [],
    redirect: _91id_935oSvk38RLSMeta?.redirect,
    component: () => import("/var/www/html/water-taxi/water-taxi-front/src/pages/parcel-ticket/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_937EXHkvdtgJMeta?.name ?? "pass-id",
    path: _91id_937EXHkvdtgJMeta?.path ?? "/pass/:id()",
    meta: _91id_937EXHkvdtgJMeta || {},
    alias: _91id_937EXHkvdtgJMeta?.alias || [],
    redirect: _91id_937EXHkvdtgJMeta?.redirect,
    component: () => import("/var/www/html/water-taxi/water-taxi-front/src/pages/pass/[id].vue").then(m => m.default || m)
  },
  {
    name: passenger_45infoIw08uNrmWTMeta?.name ?? "passenger-info",
    path: passenger_45infoIw08uNrmWTMeta?.path ?? "/passenger-info",
    meta: passenger_45infoIw08uNrmWTMeta || {},
    alias: passenger_45infoIw08uNrmWTMeta?.alias || [],
    redirect: passenger_45infoIw08uNrmWTMeta?.redirect,
    component: () => import("/var/www/html/water-taxi/water-taxi-front/src/pages/passenger-info.vue").then(m => m.default || m)
  },
  {
    name: policyE1tRE15V5ZMeta?.name ?? "policy",
    path: policyE1tRE15V5ZMeta?.path ?? "/policy",
    meta: policyE1tRE15V5ZMeta || {},
    alias: policyE1tRE15V5ZMeta?.alias || [],
    redirect: policyE1tRE15V5ZMeta?.redirect,
    component: () => import("/var/www/html/water-taxi/water-taxi-front/src/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: profile1WlMjTUgsAMeta?.name ?? "profile",
    path: profile1WlMjTUgsAMeta?.path ?? "/profile",
    meta: profile1WlMjTUgsAMeta || {},
    alias: profile1WlMjTUgsAMeta?.alias || [],
    redirect: profile1WlMjTUgsAMeta?.redirect,
    component: () => import("/var/www/html/water-taxi/water-taxi-front/src/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsKgxm1FURxYMeta?.name ?? "terms-conditions",
    path: terms_45conditionsKgxm1FURxYMeta?.path ?? "/terms-conditions",
    meta: terms_45conditionsKgxm1FURxYMeta || {},
    alias: terms_45conditionsKgxm1FURxYMeta?.alias || [],
    redirect: terms_45conditionsKgxm1FURxYMeta?.redirect,
    component: () => import("/var/www/html/water-taxi/water-taxi-front/src/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: _91id_93mMvUAXmRpuMeta?.name ?? "ticket-id",
    path: _91id_93mMvUAXmRpuMeta?.path ?? "/ticket/:id()",
    meta: _91id_93mMvUAXmRpuMeta || {},
    alias: _91id_93mMvUAXmRpuMeta?.alias || [],
    redirect: _91id_93mMvUAXmRpuMeta?.redirect,
    component: () => import("/var/www/html/water-taxi/water-taxi-front/src/pages/ticket/[id].vue").then(m => m.default || m)
  }
]