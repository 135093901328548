import { VueReCaptcha } from 'vue-recaptcha-v3';

export default defineNuxtPlugin((nuxtApp) => {    
    nuxtApp.vueApp.use(VueReCaptcha, {
        siteKey: import.meta.env.VITE_APP_RECAPTCHA_SITE_KEY,
        loaderOptions: {
            autoHideBadge: false,
            explicitRenderParameters: {
                badge: 'bottomright',
            },
            // useRecaptchaNet: true,
            // renderParameters: {
            //     hl: 'id'
            // }
        },
    });
});